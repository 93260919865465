import React from 'react'

const SVGPDF = ({color, style}) =>
  <div style={style}>
    <svg id="pdf" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 32">
      <g id="Group_307" data-name="Group 307">
        <g id="Group_306" data-name="Group 306">
          <path id="Path_19" fill={color} data-name="Path 19" d="M6.8,19.4H5.4v2H6.7a1.075,1.075,0,0,0,1-1A.987.987,0,0,0,6.8,19.4Z"/>
          <path id="Path_20" fill={color} data-name="Path 20" d="M12.1,19.4H10.8V23h1.3C14.3,23,14.4,19.4,12.1,19.4Z"/>
          <path id="Path_21" fill={color} data-name="Path 21" d="M23,15H1a.945.945,0,0,0-1,1V26a1,1,0,0,0,1,1H23a1,1,0,0,0,1-1V16A.945.945,0,0,0,23,15ZM6.8,22.4H5.4v1.1c0,.4-.2.6-.5.6s-.5-.2-.5-.6V19a.56.56,0,0,1,.5-.6H6.7a1.941,1.941,0,0,1,2,2A1.864,1.864,0,0,1,6.8,22.4ZM12.2,24H10.3c-.3,0-.6-.1-.6-.5V19a.56.56,0,0,1,.6-.5h1.8C15.8,18.5,15.7,24,12.2,24Zm7.1-4.5H17v1.3h2a.645.645,0,0,1,.6.6.56.56,0,0,1-.6.5H17v1.7a.472.472,0,0,1-.5.5c-.4,0-.6-.2-.6-.5V19c0-.3.2-.5.6-.5h2.8c.4,0,.6.2.6.5A.562.562,0,0,1,19.3,19.5Z"/>
        </g>
        <path id="Path_22" fill={color} data-name="Path 22" d="M27,7.6,20.4,1A3.69,3.69,0,0,0,18,0H7A2.946,2.946,0,0,0,4,3V13a.945.945,0,0,0,1,1,.945.945,0,0,0,1-1V3A.945.945,0,0,1,7,2h9a2.006,2.006,0,0,1,2,2V7.8A2.22,2.22,0,0,0,20.2,10H24a2.006,2.006,0,0,1,2,2V29a.945.945,0,0,1-1,1H7a.945.945,0,0,1-1-1,.945.945,0,0,0-1-1,.945.945,0,0,0-1,1,2.946,2.946,0,0,0,3,3H25a2.946,2.946,0,0,0,3-3V10A3.69,3.69,0,0,0,27,7.6Z"/>
      </g>
    </svg>
  </div>
///

export default SVGPDF;