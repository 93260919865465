import React from 'react'

const SVGLogo = ({color, style}) =>
  <div style={style}>
<svg width="276" height="65" viewBox="0 0 276 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_b)">
<path d="M7.71713 54.0654C6.15636 54.3258 3.03483 54.9332 1.73419 55.4539C0.953803 57.1896 0.173419 61.0948 0 63.2644H27.8337C28.1805 61.4419 28.0071 57.1896 27.4869 55.2804C25.8394 54.7597 23.0647 54.239 21.0704 54.0654V37.2296C22.7179 37.5768 25.059 37.8371 27.6603 37.8371C32.7761 48.9453 41.5338 62.3965 44.742 64.6529C47.6034 64.4793 53.7598 63.5247 56.2744 62.7437C57.3149 60.6609 58.0953 56.4085 58.0953 53.7183L53.3263 54.239C50.8117 52.6769 44.742 44.6929 39.7996 35.7543C46.7364 33.1509 52.2858 27.4232 52.2858 19.1789C52.2858 6.33511 41.7072 0.867824 27.2267 0.867824C19.3362 0.867824 8.58423 1.30173 1.73419 2.08278C1.47406 4.4259 1.56077 8.50467 1.90761 10.5007C3.29496 10.8478 5.98295 11.1081 7.71713 11.1081V54.0654ZM21.0704 9.45928C22.371 9.28571 24.1919 9.19893 25.8394 9.19893C33.3831 9.19893 38.8458 13.1041 38.8458 19.6128C38.8458 26.729 34.0768 29.8531 28.2673 29.8531C25.9261 29.8531 22.8913 29.6796 21.0704 29.3324V9.45928Z" fill="#848097" fill-opacity="0.66"/>
<path d="M140.649 63.2644C140.475 60.8344 139.955 57.5367 139.261 55.1936C137.961 54.6729 135.966 54.1522 134.579 53.8919L112.902 0.694262H102.93L80.299 54.1522C78.9983 54.4126 77.1774 54.9332 76.1369 55.4539C75.2698 57.6235 74.4027 60.5741 74.0559 63.2644H98.5079C98.5079 61.0948 97.901 57.2764 97.1206 55.3672C95.5598 54.9332 92.9585 54.4993 91.1376 54.3258C92.1782 52.8505 93.2187 50.1602 93.7389 48.6849L94.7794 45.8211H117.15L118.104 48.251C118.885 50.247 119.925 52.4166 121.226 53.9786C119.752 54.239 117.324 54.8465 115.85 55.3672C114.983 57.3632 113.942 61.1816 113.769 63.2644H140.649ZM106.745 13.3645C106.919 16.9226 108.653 22.9973 109.607 25.6008L114.202 37.7503H97.901L102.323 25.7744C103.364 22.9973 105.445 16.8358 105.705 13.3645H106.745Z" fill="#848097" fill-opacity="0.66"/>
<path d="M194.047 18.3979C196.561 19.7864 201.417 20.9146 204.712 20.9146C205.666 16.4887 206.36 9.28571 206.36 4.07877C201.504 1.64886 195.174 0 188.584 0C170.549 0 155.895 12.3231 155.895 33.0641C155.895 51.7223 167.514 65 185.116 65C191.879 65 198.729 63.004 203.758 60.4005C205.146 58.1442 206.099 53.9786 205.839 51.5487C200.116 53.6315 192.659 54.8465 188.151 54.8465C177.659 54.8465 170.115 47.5567 170.115 31.8491C170.115 16.2283 177.659 8.59145 187.804 8.59145C190.145 8.59145 192.139 9.02537 194.047 9.89319V18.3979Z" fill="#848097" fill-opacity="0.66"/>
<path d="M276 63.2644V46.6021C273.052 46.6021 268.109 47.1228 265.682 47.9039C265.161 49.8999 264.728 52.6769 264.554 54.9332H247.212V35.8411H260.566C260.652 36.8825 260.999 38.7917 261.346 39.8331C263.861 40.6142 268.023 40.7877 270.884 40.5274V22.737C267.763 22.737 263.861 23.2577 261.433 24.0387C261.086 25.1669 260.739 26.9025 260.652 27.8571H247.212V9.89319H264.294C264.468 11.7156 264.901 14.5794 265.335 16.1415C268.023 17.0093 272.185 17.4433 275.133 17.4433V1.73565H228.05C227.616 4.51268 227.79 7.20294 228.136 9.71963C229.524 10.1535 232.038 10.5874 233.859 10.761V54.0654C232.298 54.3258 229.177 54.9332 227.876 55.4539C227.096 57.1896 226.316 61.0948 226.142 63.2644H276Z" fill="#848097" fill-opacity="0.66"/>
</g>
<path d="M27.6157 31.2706C27.7359 31.2706 27.833 31.3076 27.907 31.3816C27.9903 31.4557 28.0319 31.5529 28.0319 31.6732C28.0319 31.7936 27.9903 31.8908 27.907 31.9648C27.833 32.0296 27.7359 32.062 27.6157 32.062H24.7439V40.5736C24.7439 40.694 24.7023 40.7958 24.619 40.8791C24.5358 40.9532 24.434 40.9902 24.3138 40.9902C24.1843 40.9902 24.078 40.9532 23.9947 40.8791C23.9207 40.7958 23.8837 40.694 23.8837 40.5736V32.062H21.0119C20.8917 32.062 20.7899 32.025 20.7067 31.9509C20.6327 31.8769 20.5957 31.7797 20.5957 31.6594C20.5957 31.5483 20.6327 31.4557 20.7067 31.3816C20.7899 31.3076 20.8917 31.2706 21.0119 31.2706H27.6157Z" fill="white"/>
<path d="M39.2488 31.2706C39.3783 31.2706 39.4801 31.3122 39.5541 31.3955C39.6373 31.4696 39.6789 31.5714 39.6789 31.701V40.5598C39.6697 40.6893 39.6234 40.7958 39.5402 40.8791C39.4569 40.9532 39.3552 40.9902 39.235 40.9902C39.1147 40.9902 39.013 40.9485 38.9297 40.8652C38.8465 40.7819 38.8049 40.6801 38.8049 40.5598V36.422H33.1722V40.5598C33.163 40.6801 33.1168 40.7819 33.0335 40.8652C32.9503 40.9485 32.8485 40.9902 32.7283 40.9902C32.5988 40.9902 32.4924 40.9532 32.4092 40.8791C32.3352 40.7958 32.2982 40.6893 32.2982 40.5598V31.701C32.2982 31.5714 32.3398 31.4696 32.4231 31.3955C32.5063 31.3122 32.6127 31.2706 32.7422 31.2706C32.8624 31.2706 32.9641 31.3122 33.0474 31.3955C33.1306 31.4788 33.1722 31.5807 33.1722 31.701V35.6166H38.8049V31.701C38.8049 31.5807 38.8465 31.4788 38.9297 31.3955C39.0222 31.3122 39.1286 31.2706 39.2488 31.2706Z" fill="white"/>
<path d="M50.2883 40.171C50.4086 40.171 50.5057 40.2126 50.5797 40.2959C50.6629 40.3792 50.7046 40.4764 50.7046 40.5875C50.7046 40.7079 50.6629 40.8051 50.5797 40.8791C50.5057 40.9532 50.4086 40.9902 50.2883 40.9902H45.1968C45.0765 40.9902 44.9748 40.9532 44.8916 40.8791C44.8176 40.7958 44.7806 40.694 44.7806 40.5736V31.6871C44.7806 31.5668 44.8176 31.4696 44.8916 31.3955C44.9748 31.3122 45.0765 31.2706 45.1968 31.2706H50.2883C50.4086 31.2706 50.5057 31.3122 50.5797 31.3955C50.6629 31.4696 50.7046 31.5668 50.7046 31.6871C50.7046 31.8075 50.6629 31.9047 50.5797 31.9787C50.5057 32.0528 50.4086 32.0898 50.2883 32.0898H45.6546V35.575H49.664C49.7843 35.575 49.8814 35.6166 49.9554 35.6999C50.0386 35.774 50.0802 35.8712 50.0802 35.9915C50.0802 36.1119 50.0386 36.2091 49.9554 36.2831C49.8814 36.3572 49.7843 36.3942 49.664 36.3942H45.6546V40.171H50.2883Z" fill="white"/>
<path d="M65.1579 31.2706C65.6666 31.2706 66.1244 31.3909 66.5314 31.6316C66.9384 31.863 67.2528 32.1916 67.4748 32.6174C67.706 33.034 67.8216 33.5061 67.8216 34.0337C67.8216 34.5614 67.706 35.0427 67.4748 35.4778C67.2528 35.9128 66.9384 36.2553 66.5314 36.5053C66.1244 36.7552 65.6666 36.8802 65.1579 36.8802H62.2861V40.5736C62.2861 40.694 62.2445 40.7958 62.1612 40.8791C62.0873 40.9532 61.9901 40.9902 61.8699 40.9902C61.7497 40.9902 61.6479 40.9532 61.5647 40.8791C61.4907 40.7958 61.4537 40.694 61.4537 40.5736V31.6871C61.4537 31.5668 61.4907 31.4696 61.5647 31.3955C61.6479 31.3122 61.7497 31.2706 61.8699 31.2706H65.1579ZM65.1579 36.0748C65.6944 36.0748 66.1291 35.8804 66.462 35.4917C66.795 35.1029 66.9615 34.6169 66.9615 34.0337C66.9615 33.4598 66.795 32.9923 66.462 32.6313C66.1291 32.2703 65.6944 32.0898 65.1579 32.0898H62.2861V36.0748H65.1579Z" fill="white"/>
<path d="M80.8725 36.1304C80.8725 37.0838 80.6736 37.9401 80.2759 38.6991C79.8782 39.4582 79.3279 40.0553 78.625 40.4903C77.922 40.9161 77.122 41.129 76.2249 41.129C75.3277 41.129 74.5277 40.9161 73.8247 40.4903C73.1218 40.0553 72.5715 39.4582 72.1738 38.6991C71.7761 37.9401 71.5772 37.0838 71.5772 36.1304C71.5772 35.1677 71.7761 34.3068 72.1738 33.5477C72.5715 32.7887 73.1218 32.1962 73.8247 31.7704C74.5277 31.3446 75.3277 31.1317 76.2249 31.1317C77.122 31.1317 77.922 31.3446 78.625 31.7704C79.3279 32.1962 79.8782 32.7887 80.2759 33.5477C80.6736 34.3068 80.8725 35.1677 80.8725 36.1304ZM79.9846 36.1304C79.9846 35.325 79.8227 34.6076 79.499 33.9782C79.1845 33.3395 78.7406 32.8442 78.1671 32.4925C77.603 32.1407 76.9555 31.9648 76.2249 31.9648C75.4942 31.9648 74.8421 32.1407 74.2687 32.4925C73.7045 32.8442 73.2605 33.3395 72.9368 33.9782C72.6224 34.6076 72.4651 35.325 72.4651 36.1304C72.4651 36.9357 72.6224 37.6577 72.9368 38.2965C73.2605 38.9259 73.7045 39.4165 74.2687 39.7683C74.8421 40.1201 75.4942 40.2959 76.2249 40.2959C76.9555 40.2959 77.603 40.1201 78.1671 39.7683C78.7406 39.4165 79.1845 38.9259 79.499 38.2965C79.8227 37.6577 79.9846 36.9357 79.9846 36.1304Z" fill="white"/>
<path d="M96.5043 31.2428C96.6338 31.2428 96.7402 31.2798 96.8234 31.3539C96.9067 31.4279 96.9483 31.5298 96.9483 31.6594L96.9205 31.8399L93.8545 40.7264C93.8267 40.8097 93.7759 40.8745 93.7019 40.9208C93.6371 40.967 93.5631 40.9902 93.4799 40.9902C93.2672 40.9902 93.1284 40.9023 93.0637 40.7264L90.6636 34.7974L88.2635 40.7264C88.1802 40.9023 88.0415 40.9902 87.8473 40.9902C87.764 40.9902 87.6854 40.967 87.6114 40.9208C87.5467 40.8745 87.5051 40.8097 87.4866 40.7264L84.4205 31.8399C84.3928 31.7843 84.3789 31.7288 84.3789 31.6732C84.3789 31.5436 84.4251 31.4418 84.5176 31.3678C84.6194 31.2845 84.7257 31.2428 84.8367 31.2428C84.9292 31.2428 85.0124 31.2659 85.0864 31.3122C85.1604 31.3585 85.2113 31.4233 85.239 31.5066L87.9583 39.4489L90.289 33.631C90.3722 33.4459 90.5017 33.358 90.6775 33.3672C90.7699 33.358 90.8486 33.3811 90.9133 33.4367C90.9873 33.4829 91.0428 33.5477 91.0798 33.631L93.3967 39.4489L96.0881 31.5066C96.1621 31.3307 96.3009 31.2428 96.5043 31.2428Z" fill="white"/>
<path d="M106.671 40.171C106.791 40.171 106.888 40.2126 106.962 40.2959C107.046 40.3792 107.087 40.4764 107.087 40.5875C107.087 40.7079 107.046 40.8051 106.962 40.8791C106.888 40.9532 106.791 40.9902 106.671 40.9902H101.58C101.459 40.9902 101.358 40.9532 101.274 40.8791C101.2 40.7958 101.163 40.694 101.163 40.5736V31.6871C101.163 31.5668 101.2 31.4696 101.274 31.3955C101.358 31.3122 101.459 31.2706 101.58 31.2706H106.671C106.791 31.2706 106.888 31.3122 106.962 31.3955C107.046 31.4696 107.087 31.5668 107.087 31.6871C107.087 31.8075 107.046 31.9047 106.962 31.9787C106.888 32.0528 106.791 32.0898 106.671 32.0898H102.037V35.575H106.047C106.167 35.575 106.264 35.6166 106.338 35.6999C106.421 35.774 106.463 35.8712 106.463 35.9915C106.463 36.1119 106.421 36.2091 106.338 36.2831C106.264 36.3572 106.167 36.3942 106.047 36.3942H102.037V40.171H106.671Z" fill="white"/>
<path d="M118.635 40.2404C118.774 40.333 118.843 40.4487 118.843 40.5875C118.843 40.6338 118.825 40.694 118.788 40.768C118.751 40.8328 118.7 40.8837 118.635 40.9208C118.57 40.9578 118.501 40.9763 118.427 40.9763C118.334 40.9763 118.256 40.9578 118.191 40.9208C117.932 40.7727 117.724 40.532 117.567 40.1987C117.41 39.8655 117.331 39.3332 117.331 38.6019C117.331 37.954 117.151 37.5004 116.79 37.2412C116.429 36.9727 115.999 36.8385 115.5 36.8385H112.42V40.5736C112.42 40.694 112.383 40.7958 112.309 40.8791C112.235 40.9532 112.138 40.9902 112.017 40.9902C111.888 40.9902 111.777 40.9532 111.684 40.8791C111.592 40.7958 111.546 40.694 111.546 40.5736V31.6871C111.546 31.5668 111.583 31.4696 111.657 31.3955C111.74 31.3122 111.842 31.2706 111.962 31.2706H115.708C116.235 31.2706 116.707 31.3863 117.123 31.6177C117.539 31.8399 117.863 32.15 118.094 32.548C118.334 32.946 118.455 33.395 118.455 33.8949C118.455 34.4873 118.297 35.0103 117.983 35.4639C117.678 35.9082 117.275 36.2137 116.776 36.3803C117.183 36.5377 117.516 36.8061 117.775 37.1857C118.034 37.5559 118.168 38.0095 118.177 38.5464C118.186 39.1203 118.223 39.523 118.288 39.7544C118.362 39.9858 118.478 40.1478 118.635 40.2404ZM115.749 36.0748C116.073 36.0471 116.374 35.9406 116.651 35.7555C116.929 35.5703 117.151 35.3204 117.317 35.0057C117.484 34.6909 117.567 34.3299 117.567 33.9226C117.567 33.395 117.386 32.9599 117.026 32.6174C116.674 32.2657 116.212 32.0898 115.638 32.0898H112.42V36.0748H115.749Z" fill="white"/>
<path d="M138.285 36.1304C138.285 37.0838 138.086 37.9401 137.688 38.6991C137.291 39.4582 136.74 40.0553 136.037 40.4903C135.334 40.9161 134.534 41.129 133.637 41.129C132.74 41.129 131.94 40.9161 131.237 40.4903C130.534 40.0553 129.984 39.4582 129.586 38.6991C129.189 37.9401 128.99 37.0838 128.99 36.1304C128.99 35.1677 129.189 34.3068 129.586 33.5477C129.984 32.7887 130.534 32.1962 131.237 31.7704C131.94 31.3446 132.74 31.1317 133.637 31.1317C134.534 31.1317 135.334 31.3446 136.037 31.7704C136.74 32.1962 137.291 32.7887 137.688 33.5477C138.086 34.3068 138.285 35.1677 138.285 36.1304ZM137.397 36.1304C137.397 35.325 137.235 34.6076 136.911 33.9782C136.597 33.3395 136.153 32.8442 135.58 32.4925C135.015 32.1407 134.368 31.9648 133.637 31.9648C132.907 31.9648 132.255 32.1407 131.681 32.4925C131.117 32.8442 130.673 33.3395 130.349 33.9782C130.035 34.6076 129.878 35.325 129.878 36.1304C129.878 36.9357 130.035 37.6577 130.349 38.2965C130.673 38.9259 131.117 39.4165 131.681 39.7683C132.255 40.1201 132.907 40.2959 133.637 40.2959C134.368 40.2959 135.015 40.1201 135.58 39.7683C136.153 39.4165 136.597 38.9259 136.911 38.2965C137.235 37.6577 137.397 36.9357 137.397 36.1304Z" fill="white"/>
<path d="M148.213 31.2706C148.333 31.2706 148.43 31.3122 148.504 31.3955C148.588 31.4696 148.629 31.5668 148.629 31.6871C148.629 31.7982 148.588 31.8954 148.504 31.9787C148.43 32.0528 148.333 32.0898 148.213 32.0898H143.593V35.575H147.589C147.709 35.575 147.806 35.612 147.88 35.6861C147.963 35.7601 148.005 35.8573 148.005 35.9776C148.005 36.098 147.963 36.1952 147.88 36.2692C147.806 36.3433 147.709 36.3803 147.589 36.3803H143.593V40.5736C143.593 40.694 143.552 40.7958 143.468 40.8791C143.394 40.9532 143.297 40.9902 143.177 40.9902C143.048 40.9902 142.941 40.9532 142.858 40.8791C142.784 40.7958 142.747 40.694 142.747 40.5736V31.6871C142.747 31.5668 142.784 31.4696 142.858 31.3955C142.941 31.3122 143.043 31.2706 143.163 31.2706H148.213Z" fill="white"/>
<path d="M166.635 40.4348C166.653 40.5088 166.663 40.5644 166.663 40.6014C166.663 40.7217 166.621 40.8189 166.538 40.893C166.455 40.9671 166.358 41.0041 166.247 41.0041C166.154 41.0041 166.071 40.9809 165.997 40.9347C165.932 40.8791 165.881 40.8097 165.844 40.7264L164.804 38.1854H160.295L159.268 40.7264C159.194 40.9115 159.069 41.0041 158.894 41.0041C158.764 41.0041 158.667 40.9717 158.602 40.9069C158.537 40.8421 158.5 40.7541 158.491 40.6431V40.6014C158.491 40.5459 158.496 40.5042 158.505 40.4764L162.168 31.5066C162.242 31.3215 162.38 31.2289 162.584 31.2289C162.676 31.2289 162.755 31.2567 162.82 31.3122C162.894 31.3585 162.949 31.4233 162.986 31.5066L166.635 40.4348ZM160.614 37.3662H164.471L162.528 32.6174L160.614 37.3662Z" fill="white"/>
<path d="M177.976 31.2706C178.096 31.2706 178.189 31.3076 178.253 31.3816C178.327 31.4557 178.364 31.5483 178.364 31.6594V40.5459C178.364 40.6847 178.323 40.7958 178.24 40.8791C178.156 40.9532 178.055 40.9902 177.934 40.9902C177.87 40.9902 177.805 40.9763 177.74 40.9485C177.685 40.9208 177.638 40.8884 177.601 40.8513L171.65 32.8257V40.6153C171.65 40.7171 171.608 40.8051 171.525 40.8791C171.451 40.9532 171.363 40.9902 171.261 40.9902C171.15 40.9902 171.058 40.9532 170.984 40.8791C170.91 40.8051 170.873 40.7171 170.873 40.6153V31.6871C170.873 31.5575 170.91 31.4557 170.984 31.3816C171.067 31.3076 171.164 31.2706 171.275 31.2706C171.423 31.2706 171.534 31.3215 171.608 31.4233L177.588 39.4906V31.6594C177.588 31.5483 177.625 31.4557 177.699 31.3816C177.773 31.3076 177.865 31.2706 177.976 31.2706Z" fill="white"/>
<path d="M190.628 40.5598C190.619 40.6801 190.573 40.7819 190.49 40.8652C190.406 40.9485 190.305 40.9902 190.184 40.9902C190.055 40.9902 189.949 40.9532 189.865 40.8791C189.791 40.7958 189.754 40.6893 189.754 40.5598V31.701C189.754 31.5714 189.796 31.4696 189.879 31.3955C189.962 31.3122 190.069 31.2706 190.198 31.2706C190.318 31.2706 190.42 31.3122 190.503 31.3955C190.587 31.4788 190.628 31.5807 190.628 31.701V40.5598Z" fill="white"/>
<path d="M201.089 40.171C201.209 40.171 201.306 40.208 201.38 40.282C201.464 40.3561 201.505 40.4533 201.505 40.5736C201.505 40.694 201.464 40.7958 201.38 40.8791C201.306 40.9532 201.209 40.9902 201.089 40.9902H196.136C196.016 40.9902 195.914 40.9532 195.831 40.8791C195.757 40.7958 195.72 40.694 195.72 40.5736V31.6871C195.72 31.5668 195.762 31.4696 195.845 31.3955C195.928 31.3122 196.034 31.2706 196.164 31.2706C196.284 31.2706 196.386 31.3122 196.469 31.3955C196.552 31.4696 196.594 31.5668 196.594 31.6871V40.171H201.089Z" fill="white"/>
<path d="M210.97 40.171C211.09 40.171 211.187 40.208 211.261 40.282C211.345 40.3561 211.386 40.4533 211.386 40.5736C211.386 40.694 211.345 40.7958 211.261 40.8791C211.187 40.9532 211.09 40.9902 210.97 40.9902H206.017C205.897 40.9902 205.795 40.9532 205.712 40.8791C205.638 40.7958 205.601 40.694 205.601 40.5736V31.6871C205.601 31.5668 205.643 31.4696 205.726 31.3955C205.809 31.3122 205.915 31.2706 206.045 31.2706C206.165 31.2706 206.267 31.3122 206.35 31.3955C206.433 31.4696 206.475 31.5668 206.475 31.6871V40.171H210.97Z" fill="white"/>
<path d="M222.078 31.2706C222.199 31.2706 222.296 31.3122 222.37 31.3955C222.453 31.4696 222.495 31.5668 222.495 31.6871V37.4356C222.495 38.1113 222.333 38.7315 222.009 39.2962C221.685 39.8609 221.246 40.3052 220.691 40.6292C220.136 40.9532 219.526 41.1152 218.86 41.1152C218.185 41.1152 217.57 40.9532 217.015 40.6292C216.46 40.3052 216.02 39.8609 215.697 39.2962C215.373 38.7315 215.211 38.1113 215.211 37.4356V31.6871C215.211 31.5668 215.248 31.4696 215.322 31.3955C215.405 31.3122 215.516 31.2706 215.655 31.2706C215.766 31.2706 215.863 31.3122 215.946 31.3955C216.03 31.4696 216.071 31.5668 216.071 31.6871V37.4356C216.071 37.954 216.196 38.4307 216.446 38.8658C216.696 39.3008 217.033 39.648 217.459 39.9071C217.893 40.1571 218.36 40.282 218.86 40.282C219.368 40.282 219.836 40.1571 220.261 39.9071C220.696 39.648 221.038 39.3008 221.288 38.8658C221.547 38.4307 221.676 37.954 221.676 37.4356V31.6871C221.676 31.5668 221.713 31.4696 221.787 31.3955C221.861 31.3122 221.958 31.2706 222.078 31.2706Z" fill="white"/>
<path d="M230.085 41.129C229.419 41.129 228.827 41.018 228.309 40.7958C227.801 40.5736 227.306 40.2265 226.825 39.7544C226.714 39.6618 226.658 39.5508 226.658 39.4212C226.658 39.3101 226.7 39.2083 226.783 39.1157C226.876 39.0231 226.978 38.9768 227.089 38.9768C227.2 38.9768 227.301 39.0231 227.394 39.1157C228.115 39.9118 229.017 40.3098 230.099 40.3098C230.765 40.3098 231.315 40.1525 231.75 39.8377C232.194 39.523 232.416 39.1111 232.416 38.6019C232.416 38.2039 232.305 37.8753 232.083 37.6161C231.87 37.3476 231.588 37.1301 231.237 36.9635C230.885 36.7969 230.437 36.6256 229.891 36.4497C229.299 36.2739 228.8 36.0795 228.393 35.8666C227.995 35.6537 227.671 35.3713 227.421 35.0196C227.172 34.6586 227.047 34.205 227.047 33.6588C227.047 33.1682 227.176 32.7331 227.435 32.3536C227.704 31.9741 228.074 31.6779 228.545 31.465C229.026 31.2428 229.576 31.1317 230.196 31.1317C230.76 31.1317 231.288 31.2289 231.778 31.4233C232.277 31.6084 232.675 31.8769 232.971 32.2286C233.11 32.386 233.179 32.5202 233.179 32.6313C233.179 32.7331 233.133 32.8303 233.04 32.9229C232.948 33.0062 232.846 33.0479 232.735 33.0479C232.643 33.0479 232.564 33.0155 232.499 32.9507C232.249 32.6545 231.916 32.4138 231.5 32.2286C231.084 32.0435 230.649 31.9509 230.196 31.9509C229.521 31.9509 228.971 32.1037 228.545 32.4092C228.12 32.7054 227.907 33.1127 227.907 33.631C227.907 34.1679 228.111 34.5799 228.517 34.8668C228.924 35.1538 229.507 35.4176 230.266 35.6583C230.922 35.8527 231.459 36.0517 231.875 36.2553C232.291 36.459 232.629 36.7506 232.888 37.1301C233.156 37.5004 233.29 37.991 233.29 38.6019C233.29 39.074 233.151 39.5045 232.874 39.8933C232.606 40.2728 232.226 40.5736 231.736 40.7958C231.255 41.018 230.705 41.129 230.085 41.129Z" fill="white"/>
<path d="M238.625 40.5598C238.615 40.6801 238.569 40.7819 238.486 40.8652C238.403 40.9485 238.301 40.9902 238.181 40.9902C238.051 40.9902 237.945 40.9532 237.862 40.8791C237.788 40.7958 237.751 40.6893 237.751 40.5598V31.701C237.751 31.5714 237.792 31.4696 237.875 31.3955C237.959 31.3122 238.065 31.2706 238.195 31.2706C238.315 31.2706 238.417 31.3122 238.5 31.3955C238.583 31.4788 238.625 31.5807 238.625 31.701V40.5598Z" fill="white"/>
<path d="M252.373 36.1304C252.373 37.0838 252.174 37.9401 251.777 38.6991C251.379 39.4582 250.829 40.0553 250.126 40.4903C249.423 40.9161 248.623 41.129 247.726 41.129C246.828 41.129 246.028 40.9161 245.326 40.4903C244.623 40.0553 244.072 39.4582 243.675 38.6991C243.277 37.9401 243.078 37.0838 243.078 36.1304C243.078 35.1677 243.277 34.3068 243.675 33.5477C244.072 32.7887 244.623 32.1962 245.326 31.7704C246.028 31.3446 246.828 31.1317 247.726 31.1317C248.623 31.1317 249.423 31.3446 250.126 31.7704C250.829 32.1962 251.379 32.7887 251.777 33.5477C252.174 34.3068 252.373 35.1677 252.373 36.1304ZM251.485 36.1304C251.485 35.325 251.324 34.6076 251 33.9782C250.685 33.3395 250.241 32.8442 249.668 32.4925C249.104 32.1407 248.456 31.9648 247.726 31.9648C246.995 31.9648 246.343 32.1407 245.77 32.4925C245.205 32.8442 244.761 33.3395 244.438 33.9782C244.123 34.6076 243.966 35.325 243.966 36.1304C243.966 36.9357 244.123 37.6577 244.438 38.2965C244.761 38.9259 245.205 39.4165 245.77 39.7683C246.343 40.1201 246.995 40.2959 247.726 40.2959C248.456 40.2959 249.104 40.1201 249.668 39.7683C250.241 39.4165 250.685 38.9259 251 38.2965C251.324 37.6577 251.485 36.9357 251.485 36.1304Z" fill="white"/>
<path d="M263.939 31.2706C264.059 31.2706 264.151 31.3076 264.216 31.3816C264.29 31.4557 264.327 31.5483 264.327 31.6594V40.5459C264.327 40.6847 264.285 40.7958 264.202 40.8791C264.119 40.9532 264.017 40.9902 263.897 40.9902C263.832 40.9902 263.767 40.9763 263.703 40.9485C263.647 40.9208 263.601 40.8884 263.564 40.8513L257.612 32.8257V40.6153C257.612 40.7171 257.571 40.8051 257.487 40.8791C257.413 40.9532 257.326 40.9902 257.224 40.9902C257.113 40.9902 257.02 40.9532 256.946 40.8791C256.872 40.8051 256.835 40.7171 256.835 40.6153V31.6871C256.835 31.5575 256.872 31.4557 256.946 31.3816C257.03 31.3076 257.127 31.2706 257.238 31.2706C257.386 31.2706 257.497 31.3215 257.571 31.4233L263.55 39.4906V31.6594C263.55 31.5483 263.587 31.4557 263.661 31.3816C263.735 31.3076 263.828 31.2706 263.939 31.2706Z" fill="white"/>
<defs>
<filter id="filter0_b" x="-9" y="-9" width="294" height="83" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feGaussianBlur in="BackgroundImage" stdDeviation="4.5"/>
<feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape"/>
</filter>
</defs>
</svg>
  </div>
///

export default SVGLogo;