import React from 'react'

const SVGLink = ({color, style}) =>
  <div style={style}>
    <svg id="link" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
      <rect id="Rectangle_318" data-name="Rectangle 318" width="32" height="32" fill="none"/>
      <path id="Path_23" fill={color} data-name="Path 23" d="M28,3H20a.945.945,0,0,0-1,1,.945.945,0,0,0,1,1h5.6L13.3,17.3a.99.99,0,0,0,1.4,1.4h0L27,6.4V12a1,1,0,0,0,2,0h0V4.1A.945.945,0,0,0,28.1,3Z"/>
      <path id="Path_24" fill={color} data-name="Path 24" d="M14,7a48.949,48.949,0,0,0-5.9.2A5.331,5.331,0,0,0,4.5,8.6a5.861,5.861,0,0,0-1.4,3.6C3,13.6,3,15.5,3,18a48.949,48.949,0,0,0,.2,5.9,5.861,5.861,0,0,0,1.4,3.6,5.861,5.861,0,0,0,3.6,1.4A50.825,50.825,0,0,0,14,29a48.949,48.949,0,0,0,5.9-.2,5.861,5.861,0,0,0,3.6-1.4,5.861,5.861,0,0,0,1.4-3.6A50.825,50.825,0,0,0,25,18a1,1,0,0,0-2,0h0a42.075,42.075,0,0,1-.2,5.6A3.676,3.676,0,0,1,22,26a3.676,3.676,0,0,1-2.4.8c-1.3.1-3.1.2-5.6.2a42.075,42.075,0,0,1-5.6-.2A3.451,3.451,0,0,1,6,26a3.351,3.351,0,0,1-.8-2.3C5.1,22.4,5,20.5,5,18a44.583,44.583,0,0,1,.2-5.7c.2-1.4.4-2,.8-2.4a3.42,3.42,0,0,1,2.3-.7A43.761,43.761,0,0,1,14,9a.945.945,0,0,0,1-1,1,1,0,0,0-1-1Z"/>
    </svg>
  </div>
///

export default SVGLink;